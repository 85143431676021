import React, { Component } from 'react';

const route = 'imgcrypt';
const color = '#00abe2';

class Imgcrypt extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem Imgcrypt">
        <h1 style={{color}}>imgcrypt</h1>
        <p className="position"><b>Hide secret messages in images</b></p>
        <p className="icon"><span role="img" aria-label="lock emoji">🔒</span></p>
        <p className="description">
          Steganography is a means of communication through sending messages that are hidden in digital pictures.
          Any picture can have a hidden message.
          The messages are hidden by slightly modifying the colors of certain pixels by an amount so small that it is unnoticeable to the human eye.
          In September 2014, I programmed an implementation of steganography in Java for my computer security class.
          This project turned in to imgcrypt, a Node implementation available soon on npm, as a fun means of sharing hidden messages.
        </p>
      </div>
    );
  }
}

export default {
  route,
  component: Imgcrypt,
  card: {
    route,
    key: route,
    color,
    title: 'imgcrypt',
    tags: ['Tooling', 'Open Source'],
    icon: '🔒',
    description: 'Hide encrypted messages in digital images',
  }
};
