import React, { Component } from 'react';

const route = 'graphql-music';
const color = '#e5494d';

class GraphQLWorkshop extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem GraphQLMusic">
        <h1 style={{color}}>graphql-music</h1>
        <p className="position"><b>A workshop in building a GraphQL API</b></p>
        <p className="icon"><span role="img" aria-label="red guitar emoji">🎸</span></p>
        <p className="description">
          In <a style={{color}} href="https://github.com/nathanchapman/graphql-music/" target="_blank" rel="noopener noreferrer">this introductory GraphQL workshop</a>, I'll show you how to build an API with music data to support clients that display information about artists, songs, lyrics, tabs (sheet music), and concerts!
          This is a great way to get hands-on experience working with GraphQL and Node.js.
        </p>
      </div>
    );
  }
}

export default {
  route,
  component: GraphQLWorkshop,
  card: {
    route,
    key: route,
    color,
    title: 'graphql-music',
    tags: ['Workshops', 'Open Source'],
    icon: '🎸',
    description: 'A workshop in building GraphQL APIs',
  }
};
