import React, { Component } from 'react';

const route = 'trakpad';
const color = '#333';

class TrakPad extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem TrakPad">
        <h1 style={{color}}>TrakPad</h1>
        <p className="position"><b>DJ integration software</b></p>
        <p className="icon"><span role="img" aria-label="microphone emoji">🎤</span></p>
        <p className="description">
          In early 2013, I began developing an integration tool for digital DJs. I was always interested in finding a low-cost,
          small DJ controller to avoid lugging around 50 lbs. of expensive gear. There were currently no better options,
          so I began developing a mapping between Traktor Pro 2, the industry standard for electronic DJs, and the Novation
          Launchpad, a low-cost, powerful, 64 button controller. The mappings started simple but eventually became the
          product they are today. The mappings can replace up to $2300 worth of equipment for only $225. Even better —
          it gives you a much smaller, lighter package that condenses 7 unique controllers' capabilities into just one.
          I also built a website where I sell the various configurations of the mappings that I've created with the tool.
        </p>
      </div>
    );
  }
}

export default {
  route,
  component: TrakPad,
  card: {
    route,
    key: route,
    color,
    title: 'TrakPad',
    tags: ['Web', 'Tooling'],
    description: 'DJ integration software for Traktor + Launchpad',
    image: '/images/portfolio/fx-grid.png',
  }
};
