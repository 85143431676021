import React, { Component } from 'react';

const route = 'delegateit';
const color = '#fcc428';

class DelegateIt extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem DelegateIt">
        <h1 style={{color}}>DelegateIt</h1>
        <p className="position"><b>Product Manager &amp; Engineer</b>, October 2015 - May 2016</p>
        <p className="description">
          DelegateIt was a personal concierge startup tailored mostly to the vacation rental industry. Groceries in the fridge of the
          vacation rental when you arrive? Tickets to tonight's concert? Reservations at that exclusive restaurant downtown?
          We handled all of that. We had web and mobile apps, as well as a phone number, that allowed you to delegate any
          task or delivery through a simple text message conversation. Trained delegators and runners would then handle
          your job quicker, cheaper, and more efficiently than you could yourself. The beautiful part was the near limitless
          scope. Food delivery, professional services, merchandise - whatever it was, you could delegate it. I was the
          product manager and an engineer for DelegateIt from October 2015 to May 2016. We participated in the <a style={{color}} href="http://longhornstartup.com/" target="_blank" rel="noopener noreferrer">Longhorn Startup</a> entrepreneurial program during the spring of 2016, under mentorship from Josh Baer.
        </p>
      </div>
    );
  }
}

export default {
  route,
  component: DelegateIt,
  card: {
    route,
    key: route,
    color,
    title: 'DelegateIt',
    tags: ['Web', 'Design'],
    description: 'Product Manager & Engineer',
    image: '/images/portfolio/delegateit-sticker.png',
  }
};
