import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Navigation from '../Navigation/Navigation';
import Intro from '../Intro/Intro';
import About from '../About/About';
import Portfolio from '../Portfolio/Portfolio';
import Overlay from '../Portfolio/Overlay/Overlay';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';
import NotFound from '../404/404';
import './Content.css';

class Content extends Component {
  render() {
    return (
      <div className="Content">
        <div className="container">
          <Switch>
            <Route exact path='/portfolio/:item' component={() => null} />
            <Route component={Navigation} />
          </Switch>
          <Switch>
            <Route
                path='/'
                exact={true}
                component={Intro}
            />
            <Route
                path='/about'
                component={About}
            />
            <Route
                path='/portfolio'
                exact={true}
                component={Portfolio}
            />
            <Route
                path='/portfolio/:item'
                component={Overlay}
            />
            <Route
                path='/contact'
                component={Contact}
            />
            <Route
              component={NotFound}
            />
          </Switch>
          <Switch>
            <Route exact path='/portfolio/:item' component={() => null} />
            <Route component={Footer} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Content;
