import React, { Component } from 'react';

const route = 'elevate';
const color = '#3cf';

class Elevate extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem Elevate">
        <h1 style={{color}}>Elevate</h1>
        <p className="position"><b>Application Development Intern</b>, June 2014 - August 2014</p>
        <p className="description">In summer 2014 I interned with <a style={{color}} href="https://www.elevate.com/" target="_blank" rel="noopener noreferrer">Elevate</a>,
          a financial services company based in Dallas-Fort Worth and London, as an Application Development
          Intern. One of the projects I worked on was an internal application for displaying statistics based on customer
          region and loan type as well as the popularity of marketing programs and customer rewards. The application has
          a 3-tiered backend consisting of an API Layer, Service Layer, and Data Access Layer. The API design allows for
          easily creating Android, iOS, and other web based applications to easily request data. The frontend is a single-page
          application that uses AngularJS to dynamically load only the new data needed for each chart as well as dynamically
          changing the size of charts and positioning on the page based on the number of charts. Each chart can be displayed
          in several ways, such as: Pie, Column, Bar, Pyramid, etc., via dropdown options without refreshing the page.
        </p>
      </div>
    );
  }
}

export default {
  route,
  component: Elevate,
  card: {
    route,
    key: route,
    color: '#4778bf',
    title: 'Elevate',
    tags: ['Companies', 'Web', 'Design'],
    description: 'Application Development Intern',
    image: '/images/portfolio/elevate-logo.png',
  }
};
