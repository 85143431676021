import React, { Component } from 'react';

const route = 'dosh';
const color = '#683caf';

class Dosh extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem Dosh">
        <h1 style={{color}}>Dosh</h1>
        <p className="position"><b>Senior Software Engineer</b>, August 2019 - May 2021</p>
        <p className="position"><b>Software Engineer II</b>, December 2018 - August 2019</p>
        <p className="description">
          <a style={{color}} href="https://dosh.com" target="_blank" rel="noopener noreferrer">Dosh</a> is the fastest growing card-linked offer platform connecting merchants directly to consumers by automatically putting cash into their wallets when they transact, driving higher spend and loyalty.
        </p>
        <p className="description">
          I was a Senior Software Engineer and tech lead on the consumer platform team, working primarily with Node.js, GraphQL, TypeScript, and AWS products to deliver great APIs, SDKs, and experiences to our mobile clients and consumers. I was primarily focused on experimentation, iteration, and feature delivery&nbsp;<span role="img" aria-label="scientist emoji">👨🏼‍🔬</span>
        </p>
      </div>
    );
  }
}

export default {
  route,
  component: Dosh,
  card: {
    route,
    key: route,
    color,
    title: 'Dosh',
    tags: ['Latest', 'Companies', 'Web', 'Mobile'],
    description: 'Senior Software Engineer',
    image: '/images/portfolio/dosh-logo.png',
  }
};
