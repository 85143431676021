import React, { Component } from 'react';

const route = 'visa';
const color = '#1a3e9f';

class Visa extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem Visa">
        <h1 style={{color}}>Visa</h1>
        <p className="position"><b>Software Engineering Intern</b>, June 2015 - August 2015</p>
        <p className="position"><b>Software Engineering Intern</b>, May 2016 - August 2016</p>
        <p className="description">
          In the summers of 2015 and 2016 I interned with <a style={{color}} href="https://usa.visa.com/" target="_blank" rel="noopener noreferrer">Visa</a> as a Software Engineering Intern working on <a style={{color}} href="https://www.cybersource.com/" target="_blank" rel="noopener noreferrer">CyberSource</a> applications.
        </p>
        <p className="description">
          In 2015 I did user interface design and front-end development moving a CyberSource application to the <a style={{color}} href="https://facebook.github.io/flux/" target="_blank" rel="noopener noreferrer">Flux</a> architecture, <a style={{color}} href="http://riotjs.com/" target="_blank" rel="noopener noreferrer">Riot.js</a> UI framework, and Object-Oriented CSS to decrease development time on new components and improve the scalability and responsiveness of the application.
        </p>
        <p className="description">
          I returned in 2016 to help migrate to <a style={{color}} href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a>/<a style={{color}} href="https://redux.js.org/" target="_blank" rel="noopener noreferrer">Redux</a>, build out a large number of React components, and integrate a UI unit testing framework. My intern projects were a <a style={{color}} href="http://yeoman.io/" target="_blank" rel="noopener noreferrer">Yeoman</a> generator for scaffolding React components and a documentation site that's built automatically from the code base.
        </p>
      </div>
    );
  }
}

export default {
  route,
  component: Visa,
  card: {
    route,
    key: route,
    color: '#171e6b',
    title: 'Visa',
    tags: ['Companies', 'Web'],
    description: 'Software Engineering Intern',
    image: '/images/portfolio/visa-logo.png',
  }
};
