import React, { Component } from 'react';
import WOW from 'wowjs';
import './404.css';

class NotFound extends Component {
  componentDidMount() {
    const wow = new WOW.WOW({
      live: false
    });
    wow.init();
  }

  render() {
    return (
      <div className="NotFound">
        <div className="heading">
          <code className="wow fadeIn" data-wow-delay="0.1s">console.log(`Yikes, that page doesn't exist! <span role="img" aria-label="Man facepalming">🤦‍♂️</span>`);</code>
          <h1 className="main-heading wow fadeIn" data-wow-delay="0.2s">Hey, you're pretty good at finding things!</h1>
          <p className="main-heading wow fadeIn" data-wow-delay="0.3s"></p>
          <p className="main-heading wow fadeIn" data-wow-delay="0.4s">Maybe you can help <a href="https://www.austinpetsalive.org/adopt/dogs" target="_blank" rel="noopener noreferrer">one of these puppers</a> find a good home?</p>
          <span className="main-heading wow fadeIn" data-wow-delay="0.5s" role="img" aria-label="Puppy with new house thanks to you">🐶🏡❤️🙌</span>
        </div>
      </div>
    );
  }
}

export default NotFound;
