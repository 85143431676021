import React, { Component } from 'react';

const route = 'udemy';
const offWhite = '#f8f8ff';
const color = '#a435f0';

class Udemy extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem Udemy">
        <img className="udemy-logo" src="/images/portfolio/udemy-logo-inverted.svg" alt="Udemy" />
        <p className="position"><b>Senior Software Engineer</b>, May 2021 - March 2022</p>
        <p className="description">
          I was a Senior Full-stack Software Engineer at <a style={{color}} href="https://udemy.com" target="_blank" rel="noopener noreferrer">Udemy</a> on the learner experience team. I was working primarily with React, TypeScript, GraphQL, Django, and our experimentation platform to drive higher conversion, retention, and customer satisfaction. I had a heavy focus on modernizing the tech stack with a migration from Django to Next.js.&nbsp;<span role="img" aria-label="lightning bolt emoji">⚡️</span>
        </p>
      </div>
    );
  }
}

export default {
  route,
  component: Udemy,
  card: {
    route,
    key: route,
    color: offWhite,
    title: 'Udemy',
    tags: ['Latest', 'Companies', 'Web'],
    description: 'Senior Software Engineer',
    image: '/images/portfolio/udemy-logo.svg',
  }
};
