import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import Content from '../Content/Content';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <LastLocationProvider>
            <Content />
          </LastLocationProvider>
        </Router>
      </div>
    );
  }
}

export default App;
