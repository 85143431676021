import React, { Component } from 'react';
import WOW from 'wowjs';
import moment from 'moment';
import numWords from 'num-words';
import './About.css';

class About extends Component {
  componentDidMount() {
    const wow = new WOW.WOW({
      live: false
    });
    wow.init();
  }

  render() {
    const now = moment();
    const nathan = moment(778377600000);
    const nathanAge = numWords(now.diff(nathan, 'years'));
    const kahlua = moment(1483920000000);
    const kahluaAge = numWords(now.diff(kahlua, 'years'));
    return <div className="About">
			<div className="heading">
				<code className="main-heading wow fadeIn" data-wow-delay="0.2s">
					console.log('Hey! <span role="img" aria-label="Waving hand">
						👋🏼
					</span>
					');
				</code>
				<h1 className="main-heading wow fadeIn" data-wow-delay="0.4s">
					I'm&nbsp;Nathan.
				</h1>
			</div>
			<p className="wow fadeIn" data-wow-delay="0.5s">
				I'm a software engineer and app developer based in Austin, Texas. I'm {nathanAge} years old and currently working for <a href="https://www.firstdollar.com/" target="_blank" rel="noopener noreferrer">
					First Dollar
				</a>. In May 2017, I graduated from <a href="https://www.utexas.edu" target="_blank" rel="noopener noreferrer">
					The University of Texas at Austin
				</a> with a Bachelor of Science in <a href="https://www.cs.utexas.edu" target="_blank" rel="noopener noreferrer">Computer Science</a> and certifications in <a href="https://www.cs.utexas.edu/concentrations/cybersecurity" target="_blank" rel="noopener noreferrer">
					Information Security
				</a> and <a href="https://www.cs.utexas.edu/concentrations/mobile-computing" target="_blank" rel="noopener noreferrer">
					Mobile Applications
				</a>. I have a passion for creating beautiful, <a href="https://www.interaction-design.org/literature/topics/accessibility" target="_blank" rel="noopener noreferrer">accessible</a>, and responsive digital experiences.
			</p>
			<p className="wow fadeIn" data-wow-delay="0.6s">
				I started programming when I was thirteen; finding new ways to challenge myself and learn new concepts. Over the years, I've been exposed to diverse programming challenges and written software in numerous languages. I’ve developed projects involving video game automation, digital music software integrations, social networking, and large-scale web and mobile applications.
			</p>
			<p className="wow fadeIn" data-wow-delay="0.7s">
				Simplicity is superior. I pay attention to the small things and try to emphasize the subtleties that make a huge difference. I'm a process and efficiency nerd that loves improving my development workflow with new technologies and tools (and helping others do the same!). I love building user-facing applications, but enjoy full-stack development as well. I'm a big proponent for social coding, open-source software, and diversity &amp; inclusion in the software field. I love helping people "level up" their programming skills and showing newcomers what they can build with software.
			</p>
			<p className="wow fadeIn" data-wow-delay="0.8s">
				I love working with companies to build applications that positively impact on the daily lives of millions of people. I'm passionate about Node.js, JavaScript, GraphQL, mobile apps, and security. I'm a music, film, and video game fanatic. In my free time I enjoy working on open-source projects, building websites and apps, kayaking, and spending time outdoors with my partner (and best friend), <a href="https://camilazalez.com" target="_blank" rel="noopener noreferrer">
					Camila
				</a>, and our {kahluaAge} year old German Shepherd, Kahlua.
			</p>
		</div>;
  }
}

export default About;
