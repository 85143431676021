import React, { Component } from 'react';

const route = 'mayonnaise';
const color = '#ef7260';

class Mayonnaise extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem Mayonnaise">
        <h1 style={{color}}>mayonnaise.js</h1>
        <p className="position"><b>Fake data generator for JavaScript</b></p>
        <p className="icon"><span role="img" aria-label="star emoji">⭐️</span></p>
        <p className="description">
          Why use Lorem Ipsum when you can use Patrick Star's awesome quotations?
          This Spongebob-themed placeholder text generator for JavaScript has tons of strings available.
          Just pass in the number of sentences you need and get the best placeholder text you've ever seen.
          Check it out on <a style={{color}} href="https://github.com/nathanchapman/mayonnaise.js" target="_blank" rel="noopener noreferrer">GitHub</a>.
        </p>
      </div>
    );
  }
}

export default {
  route,
  component: Mayonnaise,
  card: {
    route,
    key: route,
    icon: '⭐️',
    color,
    title: 'mayonnaise.js',
    path: '/portfolio/mayonnaise',
    tags: ['Tooling', 'Open Source'],
    description: 'Fake data generator for JavaScript',
  }
};
