import React, { Component } from 'react';

const route = 'key';
const color = '#3fb562';

class Key extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem Key">
        <h1 style={{color}}>Key</h1>
        <p className="position"><b>Social media connection app</b></p>
        <p className="icon"><span role="img" aria-label="key emoji">🔑</span></p>
        <p className="description">
          The Key app started as a project for my Android class at The University of Texas in the fall of 2016. Key aims to connect
          people on multiple social media accounts at once, by sharing or scanning QR codes. Users can generate a QR code
          that contains the data for whichever social media accounts they choose. When someone else scans this QR code,
          they are given the choice to connect with that person on each of the social media platforms. This shortens the
          amount of time it takes to connect with someone (no more searching and finding different monikers for each social
          media platform), but it also gives users the ability to choose which platforms they feel comfortable sharing.
          This takes the guesswork out of separating business from personal, family from friends, etc. Just met someone
          at a bar? Share only the platforms you feel comfortable connecting on, and the other person will have the choice
          to decide what they're comfortable with from the options you gave them.
        </p>
      </div>
    );
  }
}

export default {
  route,
  component: Key,
  card: {
    route,
    key: route,
    color,
    title: 'Key',
    tags: ['Mobile', 'Design'],
    icon: '🔑',
    description: 'Social media connection app',
  }
};
