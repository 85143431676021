
import React, { Component } from 'react';

const route = 'jamstack';
const color = '#c2185a';

class Jamstack extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem Jamstack">
        <h1 style={{color}}>jamstack</h1>
        <p className="position"><b>A template for creating modern progressive web apps, using GraphQL and TypeScript</b></p>
        <p className="icon"><span role="img" aria-label="strawberry emoji">🍓</span></p>
        <p className="description">
          Check it out on <a style={{color}} href="https://github.com/nathanchapman/jamstack" target="_blank" rel="noopener noreferrer">GitHub</a>.
        </p>
      </div>
    );
  }
}

export default {
  route,
  component: Jamstack,
  card: {
    route,
    key: route,
    icon: '🍓',
    color,
    title: 'jamstack',
    path: '/portfolio/jamstack',
    tags: ['Tooling', 'Open Source'],
    description: 'Netlify + React + GraphQL + Apollo + TypeScript + Codegen',
  }
};
