import React, { Component } from 'react';

const route = 'firstdollar';
const color = {
  primary: '#52bed1',
  background: '#0f2b4d',
  accent: '#b5c428',
};

class FirstDollar extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem FirstDollar">
        <img className="fd-logo" src="/images/portfolio/first-dollar.svg" alt="First Dollar" />
        <p className="position"><b>Principal Software Engineer</b>, January 2024 - Present</p>
        <p className="position"><b>Lead Software Engineer</b>, March 2022 - January 2024</p>
        <p className="description">
          <a style={{color: color.primary}} href="https://www.firstdollar.com/" target="_blank" rel="noopener noreferrer">First Dollar</a> is the health wallet for modern, member-first health plans. We power software-defined benefits that people love to use, like our Health Savings Account (HSA). I'm currently a Principal Engineer on the Commercial Applications team, focused on building Enterprise Applications, APIs, documentation, developer tools, and SDKs for our partners and the customer-facing application for their members. Check out our <a style={{color: color.accent}} href="https://developer.firstdollar.com/" target="_blank" rel="noopener noreferrer">developer portal</a> to learn more.
        </p>
      </div>
    );
  }
}

export default {
  route,
  component: FirstDollar,
  card: {
    route,
    key: route,
    color: color.background,
    title: 'First Dollar',
    tags: ['Latest', 'Companies', 'Web'],
    description: 'Principal Software Engineer',
    image: '/images/portfolio/fd-logo.png',
  }
};
