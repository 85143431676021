import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Portfolio from '../Portfolio/Portfolio';
import Typist from 'react-typist';
import WOW from 'wowjs';
import './Intro.css';

class RestartingTypist extends Component {
  state = { typing: true }

  done = () => {
    this.setState({ typing: false }, () => {
      this.timeouts.push(
        setTimeout(() => this.setState({ typing: true }), 1200)
      );
    });
  }

  componentWillMount() {
    this.timeouts = [];
  }

  componentWillUnmount() {
    this.timeouts.forEach(window.clearTimeout);
  }

  render() {
    const {children, timeout, ...props} = this.props;
    return this.state.typing ?
      <Typist {...props} onTypingDone={this.done}>{children}</Typist>
      : <span>&nbsp;</span>;
  }
};

class Intro extends Component {
  componentDidMount() {
    const wow = new WOW.WOW({
      live: false
    });

    wow.init();
  }

  render() {
    return (
      <div className="Intro">
        <div className="heading">
          <h1 className="main-heading wow fadeIn" data-wow-delay="0.3s">
            <RestartingTypist
              avgTypingDelay={75}
              onTypingDone={this.done}
            >
              <span>I build software.</span>
              <Typist.Backspace count={9} delay={2000} />
              <span>web apps.</span>
              <Typist.Backspace count={9} delay={2000} />
              <span>mobile apps.</span>
              <Typist.Backspace count={12} delay={2000} />
              <span>experiences.</span>
              <Typist.Backspace count={12} delay={2000} />
              <span>APIs.</span>
              <Typist.Backspace count={5} delay={2000} />
              <span>tooling.</span>
              <Typist.Delay ms={1500} />
              <Typist.Backspace count={16} delay={2000} />
            </RestartingTypist>
          </h1>
          <Link className="button wow fadeIn" data-wow-delay="1.3s" to="/contact">Reach Out</Link>
        </div>
        <h3 className="section-title wow fadeIn">Recent Work</h3>
        <Portfolio filter="Latest" filters={[]}/>
        <div className="more">
          <a className="link wow fadeIn" data-wow-delay="0.3s" href="/portfolio">View More</a>
        </div>
      </div>
    );
  }
}

export default Intro;
