import React, { Component } from 'react';

const route = 'snippets';
const color = '#373079';

class Snippets extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem Snippets">
        <h1 style={{color}}>vscode-javascript-snippets</h1>
        <p className="position"><b>ESNext snippets for VS Code</b></p>
        <p className="icon"><span role="img" aria-label="programmer emoji">👨🏼‍💻</span></p>
        <p className="description">
          This <a style={{color}} href="https://code.visualstudio.com/" target="_blank" rel="noopener noreferrer">Visual Studio Code</a> extension helps you develop JavaScript programs faster.
          Snippets are optimized to be short and easy to remember.
          Don't waste time typing out tedious boilerplate.
          Just start typing a snippet and press tab to see it expand into a larger code block.
        </p>
        <center>
          <a style={{color}} href="https://marketplace.visualstudio.com/items?itemName=nathanchapman.JavaScriptSnippets" target="_blank" rel="noopener noreferrer" alt="View VS Code Extension">
            <img src="https://img.shields.io/vscode-marketplace/d/nathanchapman.JavaScriptSnippets.svg" alt="Downloads" />
          </a>
        </center>
      </div>
    );
  }
}

export default {
  route,
  component: Snippets,
  card: {
    route,
    key: route,
    color,
    title: 'javascript-snippets',
    tags: ['Tooling', 'Open Source'],
    icon: '👨🏼‍💻',
    description: 'ESNext snippets for Visual Studio Code',
  }
};
