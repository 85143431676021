import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faGithub, faNpm } from '@fortawesome/free-brands-svg-icons';
import WOW from 'wowjs';
import './Footer.css';

class Footer extends Component {
  componentDidMount() {
    const wow = new WOW.WOW({
      live: false
    });
    wow.init();
  }

  render() {
    const size = '2x';
    return (
      <div className="Footer">
        <a className="wow fadeIn" data-wow-delay="0.2s" href="https://www.linkedin.com/in/nathantaylorchapman/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedinIn} size={size} />
        </a>
        <a className="wow fadeIn" data-wow-delay="0.4s" href="https://github.com/nathanchapman" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} size={size} />
        </a>
        <a className="wow fadeIn" data-wow-delay="0.6s" href="https://www.npmjs.com/~nathanchapman" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faNpm} size={size} />
        </a>
      </div>
    );
  }
}

export default Footer;
