import React, { Component } from 'react';

const color = '#0067db';
const route = 'homeaway';

class HomeAway extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem HomeAway">
        <h1 style={{color}}>HomeAway</h1>
        <p className="position"><b>Software Engineer II</b>, July 2018 - December 2018</p>
        <p className="position"><b>Software Engineer</b>, June 2017 - July 2018</p>
        <p className="description">
          After graduating from the University of Texas in May 2017, I joined <a style={{color}} href="https://www.homeaway.com/" target="_blank" rel="noopener noreferrer">HomeAway</a> full-time as a software engineer on the Mobile Services team. Our team handled the data needs of
          4 different mobile apps. Creating and maintaining API endpoints for multiple clients can be taxing so we started
          transitioning to <a style={{color}} href="http://graphql.org" target="_blank" rel="noopener noreferrer">GraphQL</a>. GraphQL enables a single endpoint that gives clients the power to ask for the exact data they need
          and nothing more, instead of traditional REST APIs that have many client specific endpoints and return large,
          generic payloads.
        </p>
        <p className="description">
          The GraphQL specification and reference implementation frame GraphQL as a singular schema. While this is great
          for smaller domain models, a singular schema grows very large when there are thousands of types in the model.
          HomeAway is unique in that there are many distinct user-facing applications that all have their own data needs,
          but many of them share baseline requirements such as user, booking, or property information. We wanted a way
          for these applications to pull in certain parts of the schema, and to keep each portion of the schema small,
          reusable, and encapsulated. This would also allow different data teams to own their portion of the schema in
          a maintainable way. We decided to break the schema into components, which can be thought of as data sources.
          This process would, however, require a lot of tooling to work.
        </p>
        <p className="description">
          I built a large amount of this tooling and helped formalize the standards for componentization of GraphQL projects
          at HomeAway, as well as worked on many of the individual components for mobile data needs. Some of these projects
          include:
        </p>
        <p className="description no-justify">
          <br />• traveler-graphql-api, the GraphQL service responsible for backing the iOS and Android mobile apps
          <br />• generator-graphql, a <a style={{color}} href="http://yeoman.io/" target="_blank" rel="noopener noreferrer">Yeoman</a> generator for GraphQL components
          <br />• hapi-graphql-server, a <a style={{color}} href="https://hapijs.com/" target="_blank" rel="noopener noreferrer">Hapi.js</a> plugin for merging GraphQL components in the server
          <br />• graphql-utils, a library containing helper functions used across components (site and locale resolution, translator, etc.)
          <br />• graphql-testing, a library that simplifies and sets the standards for unit and integration tests
          <br />• graphql-type-checker, which tests for conflicts between components published to our enterprise npm instance as part of the testing process
          <br />• graphql-type-generator, which generates GraphQL types from a <a style={{color}} href="https://swagger.io/" target="_blank" rel="noopener noreferrer">Swagger.json</a> file
        </p>
      </div>
    );
  }
}

export default {
  route,
  component: HomeAway,
  card: {
    route,
    key: route,
    color,
    title: 'HomeAway',
    tags: ['Companies', 'Web', 'Mobile'],
    description: 'Software Engineer II',
    image: '/images/portfolio/homeaway-logo.svg',
  }
};
