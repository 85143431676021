import React, { Component } from 'react';

const route = 'bash-functions';
const color = '#cb54d1';

class Bash extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="OverlayItem Bash">
        <h1 style={{color}}>bash-functions</h1>
        <p className="position"><b>A collection of useful bash/ZSH functions</b></p>
        <p className="icon"><span role="img" aria-label="crystal ball emoji">🔮</span></p>
        <p className="description">
          A collection of useful Bash/ZSH functions.
          Just drop the functions at the end of your .bashrc or .zshrc.
          These work great with the MacBook touch bar and iTerm2.
          Check it out on <a style={{color}} href="https://github.com/nathanchapman/bash-functions" target="_blank" rel="noopener noreferrer">GitHub</a>.
        </p>
      </div>
    );
  }
}

export default {
  route,
  component: Bash,
  card: {
    route,
    key: route,
    color,
    title: 'bash-functions',
    tags: ['Tooling', 'Open Source'],
    icon: '🔮',
    description: 'A collection of Bash/ZSH functions',
  }
};
