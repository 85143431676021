import React, { Component } from 'react';
import WOW from 'wowjs';
import './Contact.css';

class Contact extends Component {
    componentDidMount() {
        const wow = new WOW.WOW({
            live: false
        });
        wow.init();
    }

    render() {
        return (
            <div className="Contact">
                <div className="heading">
                    <code className="main-heading wow fadeIn" data-wow-delay="0.2s">console.log('Hey! <span role="img" aria-label="Waving hand">👋🏼</span>');</code>
                    <h1 className="main-heading wow fadeIn" data-wow-delay="0.4s">Send me a message</h1>
                </div>
                <div className="form">
                    <form action="https://formspree.io/nathanchapman@utexas.edu" method="POST">
                        <label className="field wow fadeIn" data-wow-delay="0.5s" htmlFor="name">Name</label>
                        <input className="input wow fadeIn" data-wow-delay="0.5s" maxLength="256" name="name" placeholder="What's your name?" type="text" />
                        <label className="field wow fadeIn" data-wow-delay="0.7s" htmlFor="email">Email Address</label>
                        <input className="input wow fadeIn" data-wow-delay="0.7s" maxLength="256" name="_replyto" placeholder="What's your email address?" required="required" type="email" />
                        <label className="field wow fadeIn" data-wow-delay="0.9s" htmlFor="message">Message</label>
                        <textarea className="input text-area wow fadeIn" data-wow-delay="0.9s" maxLength="5000" name="message" placeholder="What can we accomplish together?"></textarea>
                        <input className="button submit wow fadeIn" data-wow-delay="1s" type="submit" value={`SHIP IT`} />
                    </form>
                </div>
            </div>
        );
    }
}

export default Contact;
