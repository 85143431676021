import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Card.css';

const white = ['white', '#ffffff', '#f8f8ff'];

class Card extends Component {
  render() {
    const delay = this.props.delay || 0;
    const index = this.props.index || 0;
    const icon = this.props.icon ? 'hasIcon' : '';
    const path = `/portfolio/${this.props.route}`;
    const animate = this.props.animate;
    const textWrapperColor =
        white.includes(this.props.color) ? 'white' : 'color';

    return (
        <Link
            className={`Card ${animate}`}
            data-wow-delay={`${delay + 0.1 * index}s`}
            style={{'backgroundColor': this.props.color}}
            to={path}
        >
            <div className={`imageWrapper ${icon}`}>
                {this.props.icon}
                <img alt="" className="cardImage" src={this.props.image} />
            </div>
            <div className={`textWrapper ${textWrapperColor}`}>
                <h4>{this.props.title}</h4>
                <p>{this.props.description}</p>
            </div>
        </Link>
    );
  }
}

export default Card;
