import React, { Component } from 'react';
import { Components } from '../Items';
import WOW from 'wowjs';
import './Overlay.css';

class Overlay extends Component {
  constructor(props) {
    super();

    this.item = Components[props.match.params.item] || Components.default;
    this.goBack = this.goBack.bind(this);
    this.escapeFn = this.escapeFn.bind(this);
  }

  escapeFn(e) {
    if (e.keyCode === 27) {
      this.goBack();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const wow = new WOW.WOW({
      live: false
    });
    wow.init();

    document.addEventListener('keyup', this.escapeFn, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.escapeFn, false);
  }

  goBack() {
    if (this.props.history.length > 1) {
      this.props.history.goBack();
    } else {
      window.open('', '_self', '').close();
    }
  }

  render() {
    const backButton = (
      <button onClick={this.goBack}>
        <div className="close">
          <div className="lr">
            <div className="rl"></div>
          </div>
        </div>
      </button>
    );

    return (
      <div className="Overlay wow fadeIn">
        {backButton}
        {this.item}
      </div>
    );
  }
}

export default Overlay;
