import React from 'react';

import Bash from './Bash';
import DelegateIt from './DelegateIt';
import Dosh from './Dosh';
import Elevate from './Elevate';
import FirstDollar from './FirstDollar';
import GraphQLWorkshop from './GraphQLWorkshop';
import HomeAway from './HomeAway';
import Imgcrypt from './Imgcrypt';
import Jamstack from './Jamstack';
import Key from './Key';
import Mayonnaise from './Mayonnaise';
import Snippets from './Snippets';
import TrakPad from './TrakPad';
import Udemy from './Udemy';
import Visa from './Visa';

// {
//   icon: '👷🏼‍♂️',
//   color: '#4c4c4c',
//   key: 'utilities',
//   title: 'u-tilities',
//   tags: ['Web', 'Programming', 'Open Source'],
//   description: 'Lightweight CSS Utilities',
// },
// {
//   color: '#b9db0c',
//   key: 'rise',
//   title: 'RISE Credit',
//   tags: ['Latest', 'Companies', 'Web', 'Programming', 'Design'],
//   description: 'Application Development Intern',
//   image: 'https://www.risecredit.com/Static/img/logo_sm.png',
// }

export const Filters = ['Companies', 'Mobile', 'Tooling', 'Design', 'Open Source', 'Workshops'];

export const Cards = [
    FirstDollar.card,
    Udemy.card,
    Dosh.card,
    HomeAway.card,
    Visa.card,
    Elevate.card,
    DelegateIt.card,
    Key.card,
    TrakPad.card,
    Jamstack.card,
    Imgcrypt.card,
    Snippets.card,
    Mayonnaise.card,
    Bash.card,
    GraphQLWorkshop.card,
];

export const Components = {
    default: <h1>404: Portfolio item not found</h1>,
    [Bash.route]: <Bash.component />,
    [DelegateIt.route]: <DelegateIt.component />,
    [Dosh.route]: <Dosh.component />,
    [Elevate.route]: <Elevate.component />,
    [FirstDollar.route]: <FirstDollar.component />,
    [GraphQLWorkshop.route]: <GraphQLWorkshop.component />,
    [HomeAway.route]: <HomeAway.component />,
    [Imgcrypt.route]: <Imgcrypt.component />,
    [Jamstack.route]: <Jamstack.component />,
    [Key.route]: <Key.component />,
    [Mayonnaise.route]: <Mayonnaise.component />,
    [Snippets.route]: <Snippets.component />,
    [TrakPad.route]: <TrakPad.component />,
    [Udemy.route]: <Udemy.component />,
    [Visa.route]: <Visa.component />
};
